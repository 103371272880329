import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_recaptcha = _resolveComponent("vue-recaptcha")!

  return (_openBlock(), _createBlock(_component_vue_recaptcha, {
    theme: "light",
    size: "normal",
    tabindex: 0,
    onWidgetId: _cache[0] || (_cache[0] = ($event: any) => (_ctx.recaptchaWidget = $event)),
    onVerify: _cache[1] || (_cache[1] = ($event: any) => (_ctx.callbackVerify($event))),
    onExpired: _cache[2] || (_cache[2] = ($event: any) => (_ctx.callbackExpired())),
    onFail: _cache[3] || (_cache[3] = ($event: any) => (_ctx.callbackFail()))
  }))
}