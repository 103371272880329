
import { defineComponent, ref } from "vue";
import { VueRecaptcha, useRecaptcha } from "vue3-recaptcha-v2";


export default defineComponent({
  name: "Recaptcha",
  components:{VueRecaptcha},
  emit: ["rating-value"],
  setup: function(props,{emit}) {
    const { resetRecaptcha } = useRecaptcha();
    const recaptchaWidget = ref(0);

    const callbackVerify = (response) => {
      emit('recaptcha-value', true)
      // console.log(response);
    };
    const callbackExpired = () => {
      emit('recaptcha-value', false)
      // console.log("expired!");
    };
    const callbackFail = () => {
      emit('recaptcha-value', false)
      // console.log("fail");
    };
    // Reset Recaptcha action
    const actionReset = () => {
      resetRecaptcha(recaptchaWidget.value);
    };

    return {
      recaptchaWidget,
      callbackVerify,
      callbackFail,
      actionReset,
    };
  }
});
