
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import Recaptcha from "@/views/crafted/authentication/basic-flow/Recaptcha.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import globalFunctions from "@/components/globalFunctions.vue";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
    Recaptcha,
    globalFunctions,
  },
  setup() {
    const router = useRouter();
    const recaptchaStatus = ref(false);
    const regions = ref([]);
    let errorsRef = ref(null);
    const selectValue = ref(0);
    const submitButton = ref<HTMLButtonElement | null>(null);
    //Create form validation object
    const register = Yup.object().shape({
      first_name: Yup.string().required().label("First name"),
      last_name: Yup.string().required().label("Last name"),
      company: Yup.string().required().label("Company"),
      email: Yup.string().required().email().label("Email"),
      phone: Yup.string().required().label("Phone"),
      address: Yup.string().required().label("Address"),
      postal_code: Yup.string().required().label("Zip/Postal"),
      country: Yup.string().required().label("Country"),
      region: Yup.number().required().label("Region"),
      city: Yup.string().required().label("City"),
      province: Yup.string().required().label("Province"),
      password: Yup.string().required().label("Password"),
      password_confirmation: Yup.string()
        .required()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .label("Password Confirmation"),
    });

    const hideError = (key) => {
      if (key && errorsRef.value) {
        let array = JSON.parse(JSON.stringify(errorsRef.value));
        delete array[key];
        errorsRef.value = new Proxy(array, {});
      }
    };

    function success(message) {
      setTimeout(() => {
        Swal.fire({
          text: message,
          icon: "success",
          buttonsStyling: false,
          confirmButtonText: "Ok, got it!",
          customClass: {
            confirmButton: "btn btn-primary",
          },
        }).then(() => {
          router.push({ name: "sign-in" });
        });
      }, 2000);
    }

    function getError(error) {
      errorsRef.value = error;
    }
    const setStatus = (event) => {
      recaptchaStatus.value = event
    }
    const getRegion = (resp) => {
      selectValue.value = resp[0]["id"];
      regions.value = resp;
    };
    //Form submit function
    const onSubmit = async (values) => {
      let array = JSON.parse(JSON.stringify(errorsRef.value));
      let count = array ? Object.keys(array).length : 0;
      if (!submitButton.value || count > 0 || recaptchaStatus.value == false) {
        return;
      }
      submitButton.value.setAttribute("disabled", "disabled");

      submitButton.value.setAttribute("data-kt-indicator", "on");

      values.status = "New";
      ApiService.post("/", values)
        .then((resp) => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          submitButton.value?.removeAttribute("disabled");
          success(resp.data?.message);
        })
        .catch(function (error) {
          submitButton.value?.removeAttribute("data-kt-indicator");
          submitButton.value?.removeAttribute("disabled");
          if (error.response && error.response.status == 422) {
            getError(error.response?.data.errors);
          }
        });
    };

    return {
      onSubmit,
      register,
      submitButton,
      selectValue,
      hideError,
      errorsRef,
      setStatus,
      getRegion,
      regions,
    };
  },
});
